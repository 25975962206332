/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Starcar Api
 * Documentation of the Starcar Api which communicates with the C-Rent fleet management
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  BestpriceConfigDto,
  GetApiV1GetBestpriceConfigParams,
  CarglassRequestMain,
  PostApiV1CarglassMainRequest400,
  CarglassMainRequestDto,
  CarglassRequestLocation,
  PostApiV1CarglassLocationRequest400,
  CarglassLocationRequestDto,
  MercedesRequest,
  PostApiV1MercedesRequest400,
  MercedesMainRequestDto,
  PostApiV1MercedesNfzRequest400,
  MercedesNfzRequestDto,
  ContactRequest,
  PostApiV1ContactRequest400,
  ContactRequestDto,
  ContactMainRequest,
  PostApiV1ContactMainRequest400,
  ContactMainRequestDto,
  PartnerApplicationRequest,
  PostApiV1PartnerApplication400,
  PartnerApplicationRequestDto,
  Customer,
  PostApiV1NewsletterSubscribe400,
  NewsletterSubscribeRequestDto,
  PostApiV1LoginCustom200,
  LoginRequestDto,
  PostApiV1Login200,
  PostApiV1PostCustomerActivate200,
  PostApiV1PostCustomerActivate400,
  CustomerActivateRequestDto,
  PostApiV1PostCustomerPasswordReset200,
  PostApiV1PostCustomerPasswordReset400,
  CustomerPasswordResetRequestDto,
  PostApiV1PostCustomerPasswordResetFinish200,
  PostApiV1PostCustomerPasswordResetFinish400,
  PostApiV1PostCustomerActivationMail200,
  PostApiV1PostCustomerActivationMail400,
  CustomerActivationMailRequestDto,
  PostApiV1PostCustomerActivationReminderMail200,
  PostApiV1PostCustomerActivationReminderMail400,
  PutApiV1PutCustomer400,
  CustomerUpdateRequestDto,
  CustomerReservationsDto,
  CustomerPastReservationsDto,
  GetApiV1GetCustomerPastReservationsParams,
  OpenPosition,
  PostApiV1PostCustomerSupervisor400,
  CustomerSupervisorRequestDto,
  PutApiV1PutCustomerPassword200,
  PutApiV1PutCustomerPassword400,
  CustomerUpdatePasswordRequestDto,
  CustomerDocumentsDto,
  GetApiV1GetCustomerDocumentsParams,
  PaymentMethod,
  Country,
  LicenseClass,
  CarglassServiceCenter,
  FormValidationErrorsDto,
  NotificationsDto,
  PostApiV1LogNotifications400,
  PostApiV1PostDeployNotificationMail200,
  PostApiV1PostDeployNotificationMail400,
  DeployNotificationMailRequestDto,
  FeedbackDto,
  PostApiV1StoreFeedback400,
  GetApiV1GetFeedbackData200One,
  GetApiV1GetFeedbackData400,
  GetApiV1GetFeedbackDataParams,
  PostApiV1GroupChangeNotificationMail200,
  PostApiV1GroupChangeNotificationMail400,
  Group,
  GetApiV1GroupsByTypeParams,
  GetApiV1GroupsByCategory200,
  GetApiV1GroupsByCategoryParams,
  GetApiV1GroupImageParams,
  GetApiV1GroupsBatchParams,
  GetApiV1GroupParams,
  GetApiV1Groups200,
  GetApiV1GroupsParams,
  GetApiV1GroupsMapping200,
  GetApiV1GroupsMappingParams,
  LongTermRequest,
  PostApiV1LongTermRequest400,
  LongTermRequestDto,
  LongTermCheck,
  PostApiV1LongTermCheckSubmit400,
  LongTermCheckDto,
  PostApiV1UploadLongTermCheck200,
  PostApiV1UploadLongTermCheck400,
  LongTermCheckUploadDto,
  LongTermContract,
  OneWayConfigDto,
  GetApiV1GetOneWayConfigParams,
  GetApiV1GetOneWayReturnStations200,
  GetApiV1GetOneWayReturnStations400,
  GetApiV1GetOneWayReturnStationsParams,
  OneWayEstimationDto,
  PostApiV1PostEstimateOneWay400,
  OneWayRequestDto,
  Reservation,
  PostApiV1CreateOneWayReservation400,
  OneWayDistanceDto,
  GetApiV1GetOneWayDistance400,
  GetApiV1GetOneWayDistanceParams,
  RentForFreeOffersResponseDto,
  PostApiV1CreateRentForFreeReservation400,
  RentForFreeRequestDto,
  PutApiV1UpdateReservationGroup400,
  ReservationGroupDto,
  ReservationGroupsDto,
  PutApiV1UpdateReservation400,
  ReservationDtoUpdate,
  PostApiV1CreateReservation400,
  ReservationDtoCreate,
  GetApiV1GetReservationPrices400,
  Estimation,
  PostApiV1PostEstimateReservation400,
  ReservationEstimationDto,
  PostApiV1PostSubmitReservation400,
  ReservationSubmitDto,
  DeleteApiV1CancelBooking400,
  GetApiV2GetReservationGroupsParams,
  GetApiV2GetReservationPrices400,
  GetApiV1GetSales200,
  PostApiV1CreateSaleReservation400,
  SaleRequestDto,
  PostApiV1PostEstimateSale400,
  GetApiV1Stations200,
  GetApiV1StationsParams,
  Station,
  GetApiV1StationsByCityParams,
  GetApiV1StationParams,
  Holiday,
  Questionnaire,
  PutApiV1UpdateQuestionnaire400,
  QuestionnaireUpdateRequestDto,
  FeedbackResponseDto,
  GetApiV1GetFeedback400,
  GetApiV1GetFeedbackParams,
  PostApiV1UnsubscribeSurvey200,
  PostApiV1UnsubscribeSurvey400,
  SurveyUnsubscribeRequestDto
} from './backend.schemas'
import { customInstance } from './mutator/custom-instance-backend'
import type { ErrorType } from './mutator/custom-instance-backend'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getApiV1Image = (
    fileName: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Blob>(
      {url: `/api/v1/image/${fileName}`, method: 'get',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetApiV1ImageQueryKey = (fileName: string,) => [`/api/v1/image/${fileName}`];

    
export type GetApiV1ImageQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Image>>>
export type GetApiV1ImageQueryError = ErrorType<void>

export const useGetApiV1Image = <TData = Awaited<ReturnType<typeof getApiV1Image>>, TError = ErrorType<void>>(
 fileName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Image>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1ImageQueryKey(fileName);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Image>>> = ({ signal }) => getApiV1Image(fileName, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Image>>, TError, TData>(queryKey, queryFn, {enabled: !!(fileName),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1CachePurge = (
    scope: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/api/v1/cache/purge/${scope}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1CachePurgeQueryKey = (scope: string,) => [`/api/v1/cache/purge/${scope}`];

    
export type GetApiV1CachePurgeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1CachePurge>>>
export type GetApiV1CachePurgeQueryError = ErrorType<unknown>

export const useGetApiV1CachePurge = <TData = Awaited<ReturnType<typeof getApiV1CachePurge>>, TError = ErrorType<unknown>>(
 scope: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1CachePurge>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1CachePurgeQueryKey(scope);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1CachePurge>>> = ({ signal }) => getApiV1CachePurge(scope, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1CachePurge>>, TError, TData>(queryKey, queryFn, {enabled: !!(scope),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetBestpriceConfig = (
    params?: GetApiV1GetBestpriceConfigParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<BestpriceConfigDto>(
      {url: `/api/v1/config/bestprice`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetBestpriceConfigQueryKey = (params?: GetApiV1GetBestpriceConfigParams,) => [`/api/v1/config/bestprice`, ...(params ? [params]: [])];

    
export type GetApiV1GetBestpriceConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetBestpriceConfig>>>
export type GetApiV1GetBestpriceConfigQueryError = ErrorType<void>

export const useGetApiV1GetBestpriceConfig = <TData = Awaited<ReturnType<typeof getApiV1GetBestpriceConfig>>, TError = ErrorType<void>>(
 params?: GetApiV1GetBestpriceConfigParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetBestpriceConfig>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetBestpriceConfigQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetBestpriceConfig>>> = ({ signal }) => getApiV1GetBestpriceConfig(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetBestpriceConfig>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1CarglassMainRequest = (
    carglassMainRequestDto: CarglassMainRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarglassRequestMain>(
      {url: `/api/v1/carglass/main`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: carglassMainRequestDto
    },
      options);
    }
  


    export type PostApiV1CarglassMainRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CarglassMainRequest>>>
    export type PostApiV1CarglassMainRequestMutationBody = CarglassMainRequestDto
    export type PostApiV1CarglassMainRequestMutationError = ErrorType<PostApiV1CarglassMainRequest400 | void>

    export const usePostApiV1CarglassMainRequest = <TError = ErrorType<PostApiV1CarglassMainRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CarglassMainRequest>>, TError,{data: CarglassMainRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CarglassMainRequest>>, {data: CarglassMainRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1CarglassMainRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CarglassMainRequest>>, TError, {data: CarglassMainRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1CarglassLocationRequest = (
    carglassLocationRequestDto: CarglassLocationRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarglassRequestLocation>(
      {url: `/api/v1/carglass/location`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: carglassLocationRequestDto
    },
      options);
    }
  


    export type PostApiV1CarglassLocationRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CarglassLocationRequest>>>
    export type PostApiV1CarglassLocationRequestMutationBody = CarglassLocationRequestDto
    export type PostApiV1CarglassLocationRequestMutationError = ErrorType<PostApiV1CarglassLocationRequest400 | void>

    export const usePostApiV1CarglassLocationRequest = <TError = ErrorType<PostApiV1CarglassLocationRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CarglassLocationRequest>>, TError,{data: CarglassLocationRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CarglassLocationRequest>>, {data: CarglassLocationRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1CarglassLocationRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CarglassLocationRequest>>, TError, {data: CarglassLocationRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1MercedesRequest = (
    mercedesMainRequestDto: MercedesMainRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MercedesRequest>(
      {url: `/api/v1/mercedes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mercedesMainRequestDto
    },
      options);
    }
  


    export type PostApiV1MercedesRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1MercedesRequest>>>
    export type PostApiV1MercedesRequestMutationBody = MercedesMainRequestDto
    export type PostApiV1MercedesRequestMutationError = ErrorType<PostApiV1MercedesRequest400 | void>

    export const usePostApiV1MercedesRequest = <TError = ErrorType<PostApiV1MercedesRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1MercedesRequest>>, TError,{data: MercedesMainRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1MercedesRequest>>, {data: MercedesMainRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1MercedesRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1MercedesRequest>>, TError, {data: MercedesMainRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1MercedesNfzRequest = (
    mercedesNfzRequestDto: MercedesNfzRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MercedesRequest>(
      {url: `/api/v1/mercedes/nfz`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mercedesNfzRequestDto
    },
      options);
    }
  


    export type PostApiV1MercedesNfzRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1MercedesNfzRequest>>>
    export type PostApiV1MercedesNfzRequestMutationBody = MercedesNfzRequestDto
    export type PostApiV1MercedesNfzRequestMutationError = ErrorType<PostApiV1MercedesNfzRequest400 | void>

    export const usePostApiV1MercedesNfzRequest = <TError = ErrorType<PostApiV1MercedesNfzRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1MercedesNfzRequest>>, TError,{data: MercedesNfzRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1MercedesNfzRequest>>, {data: MercedesNfzRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1MercedesNfzRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1MercedesNfzRequest>>, TError, {data: MercedesNfzRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1ContactRequest = (
    contactRequestDto: ContactRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ContactRequest>(
      {url: `/api/v1/contact`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contactRequestDto
    },
      options);
    }
  


    export type PostApiV1ContactRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1ContactRequest>>>
    export type PostApiV1ContactRequestMutationBody = ContactRequestDto
    export type PostApiV1ContactRequestMutationError = ErrorType<PostApiV1ContactRequest400 | void>

    export const usePostApiV1ContactRequest = <TError = ErrorType<PostApiV1ContactRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1ContactRequest>>, TError,{data: ContactRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1ContactRequest>>, {data: ContactRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1ContactRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1ContactRequest>>, TError, {data: ContactRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1ContactMainRequest = (
    contactMainRequestDto: ContactMainRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ContactMainRequest>(
      {url: `/api/v1/contactMain`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contactMainRequestDto
    },
      options);
    }
  


    export type PostApiV1ContactMainRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1ContactMainRequest>>>
    export type PostApiV1ContactMainRequestMutationBody = ContactMainRequestDto
    export type PostApiV1ContactMainRequestMutationError = ErrorType<PostApiV1ContactMainRequest400 | void>

    export const usePostApiV1ContactMainRequest = <TError = ErrorType<PostApiV1ContactMainRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1ContactMainRequest>>, TError,{data: ContactMainRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1ContactMainRequest>>, {data: ContactMainRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1ContactMainRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1ContactMainRequest>>, TError, {data: ContactMainRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PartnerApplication = (
    partnerApplicationRequestDto: PartnerApplicationRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
formData.append('company', partnerApplicationRequestDto.company)
formData.append('salutation', partnerApplicationRequestDto.salutation)
formData.append('firstName', partnerApplicationRequestDto.firstName)
formData.append('lastName', partnerApplicationRequestDto.lastName)
formData.append('street', partnerApplicationRequestDto.street)
formData.append('zip', partnerApplicationRequestDto.zip)
formData.append('city', partnerApplicationRequestDto.city)
formData.append('phoneNumber', partnerApplicationRequestDto.phoneNumber)
formData.append('mobilePhoneNumber', partnerApplicationRequestDto.mobilePhoneNumber)
formData.append('email', partnerApplicationRequestDto.email)
if(partnerApplicationRequestDto.website !== undefined) {
 formData.append('website', partnerApplicationRequestDto.website)
 }
if(partnerApplicationRequestDto.tradeRegister !== undefined) {
 formData.append('tradeRegister', partnerApplicationRequestDto.tradeRegister)
 }
if(partnerApplicationRequestDto.tradeRegisterNumber !== undefined) {
 formData.append('tradeRegisterNumber', partnerApplicationRequestDto.tradeRegisterNumber)
 }
formData.append('stationStreet', partnerApplicationRequestDto.stationStreet)
formData.append('stationCity', partnerApplicationRequestDto.stationCity)
formData.append('stationLocation', partnerApplicationRequestDto.stationLocation)
formData.append('parkingCount', partnerApplicationRequestDto.parkingCount)
formData.append('employeeCount', partnerApplicationRequestDto.employeeCount)
if(partnerApplicationRequestDto.fleetSize !== undefined) {
 formData.append('fleetSize', partnerApplicationRequestDto.fleetSize)
 }
if(partnerApplicationRequestDto.carCount !== undefined) {
 formData.append('carCount', partnerApplicationRequestDto.carCount)
 }
if(partnerApplicationRequestDto.truckCount !== undefined) {
 formData.append('truckCount', partnerApplicationRequestDto.truckCount)
 }
formData.append('property', partnerApplicationRequestDto.property)
if(partnerApplicationRequestDto.rentalDuration !== undefined) {
 formData.append('rentalDuration', partnerApplicationRequestDto.rentalDuration)
 }
formData.append('vita', partnerApplicationRequestDto.vita)
formData.append('application', partnerApplicationRequestDto.application)
formData.append('map', partnerApplicationRequestDto.map)
if(partnerApplicationRequestDto.image1 !== undefined) {
 formData.append('image1', partnerApplicationRequestDto.image1)
 }
if(partnerApplicationRequestDto.image2 !== undefined) {
 formData.append('image2', partnerApplicationRequestDto.image2)
 }
if(partnerApplicationRequestDto.image3 !== undefined) {
 formData.append('image3', partnerApplicationRequestDto.image3)
 }
formData.append('captchaToken', partnerApplicationRequestDto.captchaToken)

      return customInstance<PartnerApplicationRequest>(
      {url: `/api/v1/partner/application`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


    export type PostApiV1PartnerApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PartnerApplication>>>
    export type PostApiV1PartnerApplicationMutationBody = PartnerApplicationRequestDto
    export type PostApiV1PartnerApplicationMutationError = ErrorType<PostApiV1PartnerApplication400 | void>

    export const usePostApiV1PartnerApplication = <TError = ErrorType<PostApiV1PartnerApplication400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PartnerApplication>>, TError,{data: PartnerApplicationRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PartnerApplication>>, {data: PartnerApplicationRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PartnerApplication(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PartnerApplication>>, TError, {data: PartnerApplicationRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1NewsletterSubscribe = (
    newsletterSubscribeRequestDto: NewsletterSubscribeRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Customer>(
      {url: `/api/v1/newsletter/subscribe`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: newsletterSubscribeRequestDto
    },
      options);
    }
  


    export type PostApiV1NewsletterSubscribeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1NewsletterSubscribe>>>
    export type PostApiV1NewsletterSubscribeMutationBody = NewsletterSubscribeRequestDto
    export type PostApiV1NewsletterSubscribeMutationError = ErrorType<PostApiV1NewsletterSubscribe400 | void>

    export const usePostApiV1NewsletterSubscribe = <TError = ErrorType<PostApiV1NewsletterSubscribe400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1NewsletterSubscribe>>, TError,{data: NewsletterSubscribeRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1NewsletterSubscribe>>, {data: NewsletterSubscribeRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1NewsletterSubscribe(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1NewsletterSubscribe>>, TError, {data: NewsletterSubscribeRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1LoginCustom = (
    loginRequestDto: LoginRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1LoginCustom200>(
      {url: `/api/v1/login/custom`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginRequestDto
    },
      options);
    }
  


    export type PostApiV1LoginCustomMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1LoginCustom>>>
    export type PostApiV1LoginCustomMutationBody = LoginRequestDto
    export type PostApiV1LoginCustomMutationError = ErrorType<void>

    export const usePostApiV1LoginCustom = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1LoginCustom>>, TError,{data: LoginRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1LoginCustom>>, {data: LoginRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1LoginCustom(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1LoginCustom>>, TError, {data: LoginRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1Login = (
    loginRequestDto: LoginRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1Login200>(
      {url: `/api/v1/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginRequestDto
    },
      options);
    }
  


    export type PostApiV1LoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Login>>>
    export type PostApiV1LoginMutationBody = LoginRequestDto
    export type PostApiV1LoginMutationError = ErrorType<void>

    export const usePostApiV1Login = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Login>>, TError,{data: LoginRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Login>>, {data: LoginRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Login(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Login>>, TError, {data: LoginRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostCustomerActivate = (
    customerActivateRequestDto: CustomerActivateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostCustomerActivate200>(
      {url: `/api/v1/customer/activate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerActivateRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerActivateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerActivate>>>
    export type PostApiV1PostCustomerActivateMutationBody = CustomerActivateRequestDto
    export type PostApiV1PostCustomerActivateMutationError = ErrorType<PostApiV1PostCustomerActivate400 | void>

    export const usePostApiV1PostCustomerActivate = <TError = ErrorType<PostApiV1PostCustomerActivate400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerActivate>>, TError,{data: CustomerActivateRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerActivate>>, {data: CustomerActivateRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerActivate(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerActivate>>, TError, {data: CustomerActivateRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostCustomerPasswordReset = (
    customerPasswordResetRequestDto: CustomerPasswordResetRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostCustomerPasswordReset200>(
      {url: `/api/v1/customer/password/reset`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerPasswordResetRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerPasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordReset>>>
    export type PostApiV1PostCustomerPasswordResetMutationBody = CustomerPasswordResetRequestDto
    export type PostApiV1PostCustomerPasswordResetMutationError = ErrorType<PostApiV1PostCustomerPasswordReset400 | void>

    export const usePostApiV1PostCustomerPasswordReset = <TError = ErrorType<PostApiV1PostCustomerPasswordReset400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordReset>>, TError,{data: CustomerPasswordResetRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordReset>>, {data: CustomerPasswordResetRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerPasswordReset(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordReset>>, TError, {data: CustomerPasswordResetRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostCustomerPasswordResetFinish = (
    customerActivateRequestDto: CustomerActivateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostCustomerPasswordResetFinish200>(
      {url: `/api/v1/customer/password/reset/finish`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerActivateRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerPasswordResetFinishMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordResetFinish>>>
    export type PostApiV1PostCustomerPasswordResetFinishMutationBody = CustomerActivateRequestDto
    export type PostApiV1PostCustomerPasswordResetFinishMutationError = ErrorType<PostApiV1PostCustomerPasswordResetFinish400 | void>

    export const usePostApiV1PostCustomerPasswordResetFinish = <TError = ErrorType<PostApiV1PostCustomerPasswordResetFinish400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordResetFinish>>, TError,{data: CustomerActivateRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordResetFinish>>, {data: CustomerActivateRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerPasswordResetFinish(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerPasswordResetFinish>>, TError, {data: CustomerActivateRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostCustomerActivationMail = (
    customerActivationMailRequestDto: CustomerActivationMailRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostCustomerActivationMail200>(
      {url: `/api/v1/user/activation/email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerActivationMailRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerActivationMailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerActivationMail>>>
    export type PostApiV1PostCustomerActivationMailMutationBody = CustomerActivationMailRequestDto
    export type PostApiV1PostCustomerActivationMailMutationError = ErrorType<PostApiV1PostCustomerActivationMail400 | void>

    export const usePostApiV1PostCustomerActivationMail = <TError = ErrorType<PostApiV1PostCustomerActivationMail400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerActivationMail>>, TError,{data: CustomerActivationMailRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerActivationMail>>, {data: CustomerActivationMailRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerActivationMail(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerActivationMail>>, TError, {data: CustomerActivationMailRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostCustomerActivationReminderMail = (
    customerActivationMailRequestDto: CustomerActivationMailRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostCustomerActivationReminderMail200>(
      {url: `/api/v1/user/activation/email/reminder`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerActivationMailRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerActivationReminderMailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerActivationReminderMail>>>
    export type PostApiV1PostCustomerActivationReminderMailMutationBody = CustomerActivationMailRequestDto
    export type PostApiV1PostCustomerActivationReminderMailMutationError = ErrorType<PostApiV1PostCustomerActivationReminderMail400 | void>

    export const usePostApiV1PostCustomerActivationReminderMail = <TError = ErrorType<PostApiV1PostCustomerActivationReminderMail400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerActivationReminderMail>>, TError,{data: CustomerActivationMailRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerActivationReminderMail>>, {data: CustomerActivationMailRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerActivationReminderMail(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerActivationReminderMail>>, TError, {data: CustomerActivationMailRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetCustomer = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Customer>(
      {url: `/api/v1/customer`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerQueryKey = () => [`/api/v1/customer`];

    
export type GetApiV1GetCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomer>>>
export type GetApiV1GetCustomerQueryError = ErrorType<void>

export const useGetApiV1GetCustomer = <TData = Awaited<ReturnType<typeof getApiV1GetCustomer>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomer>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomer>>> = ({ signal }) => getApiV1GetCustomer(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomer>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const putApiV1PutCustomer = (
    customerUpdateRequestDto: CustomerUpdateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Customer>(
      {url: `/api/v1/customer`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customerUpdateRequestDto
    },
      options);
    }
  


    export type PutApiV1PutCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1PutCustomer>>>
    export type PutApiV1PutCustomerMutationBody = CustomerUpdateRequestDto
    export type PutApiV1PutCustomerMutationError = ErrorType<PutApiV1PutCustomer400 | void>

    export const usePutApiV1PutCustomer = <TError = ErrorType<PutApiV1PutCustomer400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1PutCustomer>>, TError,{data: CustomerUpdateRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1PutCustomer>>, {data: CustomerUpdateRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1PutCustomer(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1PutCustomer>>, TError, {data: CustomerUpdateRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetCustomerReservations = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CustomerReservationsDto>(
      {url: `/api/v1/customer/reservation`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerReservationsQueryKey = () => [`/api/v1/customer/reservation`];

    
export type GetApiV1GetCustomerReservationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomerReservations>>>
export type GetApiV1GetCustomerReservationsQueryError = ErrorType<void>

export const useGetApiV1GetCustomerReservations = <TData = Awaited<ReturnType<typeof getApiV1GetCustomerReservations>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomerReservations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerReservationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomerReservations>>> = ({ signal }) => getApiV1GetCustomerReservations(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomerReservations>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetCustomerRunningReservations = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CustomerReservationsDto>(
      {url: `/api/v1/customer/runningReservations`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerRunningReservationsQueryKey = () => [`/api/v1/customer/runningReservations`];

    
export type GetApiV1GetCustomerRunningReservationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomerRunningReservations>>>
export type GetApiV1GetCustomerRunningReservationsQueryError = ErrorType<void>

export const useGetApiV1GetCustomerRunningReservations = <TData = Awaited<ReturnType<typeof getApiV1GetCustomerRunningReservations>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomerRunningReservations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerRunningReservationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomerRunningReservations>>> = ({ signal }) => getApiV1GetCustomerRunningReservations(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomerRunningReservations>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetCustomerPastReservations = (
    params?: GetApiV1GetCustomerPastReservationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CustomerPastReservationsDto>(
      {url: `/api/v1/customer/pastReservations`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerPastReservationsQueryKey = (params?: GetApiV1GetCustomerPastReservationsParams,) => [`/api/v1/customer/pastReservations`, ...(params ? [params]: [])];

    
export type GetApiV1GetCustomerPastReservationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomerPastReservations>>>
export type GetApiV1GetCustomerPastReservationsQueryError = ErrorType<void>

export const useGetApiV1GetCustomerPastReservations = <TData = Awaited<ReturnType<typeof getApiV1GetCustomerPastReservations>>, TError = ErrorType<void>>(
 params?: GetApiV1GetCustomerPastReservationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomerPastReservations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerPastReservationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomerPastReservations>>> = ({ signal }) => getApiV1GetCustomerPastReservations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomerPastReservations>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetCustomerOpenPositions = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<OpenPosition>(
      {url: `/api/v1/customer/openPositions`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerOpenPositionsQueryKey = () => [`/api/v1/customer/openPositions`];

    
export type GetApiV1GetCustomerOpenPositionsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomerOpenPositions>>>
export type GetApiV1GetCustomerOpenPositionsQueryError = ErrorType<void>

export const useGetApiV1GetCustomerOpenPositions = <TData = Awaited<ReturnType<typeof getApiV1GetCustomerOpenPositions>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomerOpenPositions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerOpenPositionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomerOpenPositions>>> = ({ signal }) => getApiV1GetCustomerOpenPositions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomerOpenPositions>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1PostCustomerSupervisor = (
    customerSupervisorRequestDto: CustomerSupervisorRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ContactRequest>(
      {url: `/api/v1/customer/business/supervisor`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customerSupervisorRequestDto
    },
      options);
    }
  


    export type PostApiV1PostCustomerSupervisorMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostCustomerSupervisor>>>
    export type PostApiV1PostCustomerSupervisorMutationBody = CustomerSupervisorRequestDto
    export type PostApiV1PostCustomerSupervisorMutationError = ErrorType<PostApiV1PostCustomerSupervisor400 | void>

    export const usePostApiV1PostCustomerSupervisor = <TError = ErrorType<PostApiV1PostCustomerSupervisor400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostCustomerSupervisor>>, TError,{data: CustomerSupervisorRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostCustomerSupervisor>>, {data: CustomerSupervisorRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostCustomerSupervisor(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostCustomerSupervisor>>, TError, {data: CustomerSupervisorRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1CustomerReservationConfirmation = (
    bookingNumber: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Blob>(
      {url: `/api/v1/customer/reservation/${bookingNumber}/confirmation`, method: 'get',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetApiV1CustomerReservationConfirmationQueryKey = (bookingNumber: number,) => [`/api/v1/customer/reservation/${bookingNumber}/confirmation`];

    
export type GetApiV1CustomerReservationConfirmationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1CustomerReservationConfirmation>>>
export type GetApiV1CustomerReservationConfirmationQueryError = ErrorType<void>

export const useGetApiV1CustomerReservationConfirmation = <TData = Awaited<ReturnType<typeof getApiV1CustomerReservationConfirmation>>, TError = ErrorType<void>>(
 bookingNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1CustomerReservationConfirmation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1CustomerReservationConfirmationQueryKey(bookingNumber);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1CustomerReservationConfirmation>>> = ({ signal }) => getApiV1CustomerReservationConfirmation(bookingNumber, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1CustomerReservationConfirmation>>, TError, TData>(queryKey, queryFn, {enabled: !!(bookingNumber),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1CustomerInvoice = (
    billNumber: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Blob>(
      {url: `/api/v1/customer/invoice/${billNumber}`, method: 'get',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetApiV1CustomerInvoiceQueryKey = (billNumber: string,) => [`/api/v1/customer/invoice/${billNumber}`];

    
export type GetApiV1CustomerInvoiceQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1CustomerInvoice>>>
export type GetApiV1CustomerInvoiceQueryError = ErrorType<void>

export const useGetApiV1CustomerInvoice = <TData = Awaited<ReturnType<typeof getApiV1CustomerInvoice>>, TError = ErrorType<void>>(
 billNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1CustomerInvoice>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1CustomerInvoiceQueryKey(billNumber);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1CustomerInvoice>>> = ({ signal }) => getApiV1CustomerInvoice(billNumber, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1CustomerInvoice>>, TError, TData>(queryKey, queryFn, {enabled: !!(billNumber),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const putApiV1PutCustomerPassword = (
    customerUpdatePasswordRequestDto: CustomerUpdatePasswordRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PutApiV1PutCustomerPassword200>(
      {url: `/api/v1/customer/password`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customerUpdatePasswordRequestDto
    },
      options);
    }
  


    export type PutApiV1PutCustomerPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1PutCustomerPassword>>>
    export type PutApiV1PutCustomerPasswordMutationBody = CustomerUpdatePasswordRequestDto
    export type PutApiV1PutCustomerPasswordMutationError = ErrorType<PutApiV1PutCustomerPassword400 | void>

    export const usePutApiV1PutCustomerPassword = <TError = ErrorType<PutApiV1PutCustomerPassword400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1PutCustomerPassword>>, TError,{data: CustomerUpdatePasswordRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1PutCustomerPassword>>, {data: CustomerUpdatePasswordRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1PutCustomerPassword(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1PutCustomerPassword>>, TError, {data: CustomerUpdatePasswordRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetCustomerDocuments = (
    params?: GetApiV1GetCustomerDocumentsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CustomerDocumentsDto>(
      {url: `/api/v1/customer/documents`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetCustomerDocumentsQueryKey = (params?: GetApiV1GetCustomerDocumentsParams,) => [`/api/v1/customer/documents`, ...(params ? [params]: [])];

    
export type GetApiV1GetCustomerDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetCustomerDocuments>>>
export type GetApiV1GetCustomerDocumentsQueryError = ErrorType<void>

export const useGetApiV1GetCustomerDocuments = <TData = Awaited<ReturnType<typeof getApiV1GetCustomerDocuments>>, TError = ErrorType<void>>(
 params?: GetApiV1GetCustomerDocumentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetCustomerDocuments>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetCustomerDocumentsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetCustomerDocuments>>> = ({ signal }) => getApiV1GetCustomerDocuments(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetCustomerDocuments>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1PaymentMethods = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<PaymentMethod[]>(
      {url: `/api/v1/paymentMethods`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1PaymentMethodsQueryKey = () => [`/api/v1/paymentMethods`];

    
export type GetApiV1PaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1PaymentMethods>>>
export type GetApiV1PaymentMethodsQueryError = ErrorType<void>

export const useGetApiV1PaymentMethods = <TData = Awaited<ReturnType<typeof getApiV1PaymentMethods>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1PaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1PaymentMethodsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1PaymentMethods>>> = ({ signal }) => getApiV1PaymentMethods(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1PaymentMethods>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1Countries = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Country[]>(
      {url: `/api/v1/countries`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1CountriesQueryKey = () => [`/api/v1/countries`];

    
export type GetApiV1CountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Countries>>>
export type GetApiV1CountriesQueryError = ErrorType<void>

export const useGetApiV1Countries = <TData = Awaited<ReturnType<typeof getApiV1Countries>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Countries>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1CountriesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Countries>>> = ({ signal }) => getApiV1Countries(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Countries>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1LicenseClasses = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<LicenseClass[]>(
      {url: `/api/v1/licenseClasses`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1LicenseClassesQueryKey = () => [`/api/v1/licenseClasses`];

    
export type GetApiV1LicenseClassesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1LicenseClasses>>>
export type GetApiV1LicenseClassesQueryError = ErrorType<void>

export const useGetApiV1LicenseClasses = <TData = Awaited<ReturnType<typeof getApiV1LicenseClasses>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1LicenseClasses>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1LicenseClassesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1LicenseClasses>>> = ({ signal }) => getApiV1LicenseClasses(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1LicenseClasses>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1CarglassServiceCenters = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CarglassServiceCenter[]>(
      {url: `/api/v1/carglassServiceCenters`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1CarglassServiceCentersQueryKey = () => [`/api/v1/carglassServiceCenters`];

    
export type GetApiV1CarglassServiceCentersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1CarglassServiceCenters>>>
export type GetApiV1CarglassServiceCentersQueryError = ErrorType<void>

export const useGetApiV1CarglassServiceCenters = <TData = Awaited<ReturnType<typeof getApiV1CarglassServiceCenters>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1CarglassServiceCenters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1CarglassServiceCentersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1CarglassServiceCenters>>> = ({ signal }) => getApiV1CarglassServiceCenters(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1CarglassServiceCenters>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1SetBelboonSession = (
    belboonClickId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/api/v1/belboon/session/${belboonClickId}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1SetBelboonSessionQueryKey = (belboonClickId: string,) => [`/api/v1/belboon/session/${belboonClickId}`];

    
export type GetApiV1SetBelboonSessionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1SetBelboonSession>>>
export type GetApiV1SetBelboonSessionQueryError = ErrorType<unknown>

export const useGetApiV1SetBelboonSession = <TData = Awaited<ReturnType<typeof getApiV1SetBelboonSession>>, TError = ErrorType<unknown>>(
 belboonClickId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1SetBelboonSession>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1SetBelboonSessionQueryKey(belboonClickId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1SetBelboonSession>>> = ({ signal }) => getApiV1SetBelboonSession(belboonClickId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1SetBelboonSession>>, TError, TData>(queryKey, queryFn, {enabled: !!(belboonClickId),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1SetBelboonSession = (
    belboonClickId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/belboon/session/${belboonClickId}`, method: 'post'
    },
      options);
    }
  


    export type PostApiV1SetBelboonSessionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1SetBelboonSession>>>
    
    export type PostApiV1SetBelboonSessionMutationError = ErrorType<unknown>

    export const usePostApiV1SetBelboonSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1SetBelboonSession>>, TError,{belboonClickId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1SetBelboonSession>>, {belboonClickId: string}> = (props) => {
          const {belboonClickId} = props ?? {};

          return  postApiV1SetBelboonSession(belboonClickId,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1SetBelboonSession>>, TError, {belboonClickId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1Xml2json = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/xml2json`, method: 'post'
    },
      options);
    }
  


    export type PostApiV1Xml2jsonMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Xml2json>>>
    
    export type PostApiV1Xml2jsonMutationError = ErrorType<void>

    export const usePostApiV1Xml2json = <TError = ErrorType<void>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Xml2json>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Xml2json>>, TVariables> = () => {
          

          return  postApiV1Xml2json(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1Xml2json>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1ClearCache = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<unknown>(
      {url: `/api/v1/clearCache`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1ClearCacheQueryKey = () => [`/api/v1/clearCache`];

    
export type GetApiV1ClearCacheQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ClearCache>>>
export type GetApiV1ClearCacheQueryError = ErrorType<void>

export const useGetApiV1ClearCache = <TData = Awaited<ReturnType<typeof getApiV1ClearCache>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1ClearCache>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1ClearCacheQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ClearCache>>> = ({ signal }) => getApiV1ClearCache(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1ClearCache>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1LogFormValidationErrors = (
    formValidationErrorsDto: FormValidationErrorsDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormValidationErrorsDto>(
      {url: `/api/v1/logFormValidationErrors`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formValidationErrorsDto
    },
      options);
    }
  


    export type PostApiV1LogFormValidationErrorsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1LogFormValidationErrors>>>
    export type PostApiV1LogFormValidationErrorsMutationBody = FormValidationErrorsDto
    export type PostApiV1LogFormValidationErrorsMutationError = ErrorType<void>

    export const usePostApiV1LogFormValidationErrors = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1LogFormValidationErrors>>, TError,{data: FormValidationErrorsDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1LogFormValidationErrors>>, {data: FormValidationErrorsDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1LogFormValidationErrors(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1LogFormValidationErrors>>, TError, {data: FormValidationErrorsDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1LogNotifications = (
    notificationsDto: NotificationsDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<NotificationsDto>(
      {url: `/api/v1/logNotifications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: notificationsDto
    },
      options);
    }
  


    export type PostApiV1LogNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1LogNotifications>>>
    export type PostApiV1LogNotificationsMutationBody = NotificationsDto
    export type PostApiV1LogNotificationsMutationError = ErrorType<PostApiV1LogNotifications400 | void>

    export const usePostApiV1LogNotifications = <TError = ErrorType<PostApiV1LogNotifications400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1LogNotifications>>, TError,{data: NotificationsDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1LogNotifications>>, {data: NotificationsDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1LogNotifications(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1LogNotifications>>, TError, {data: NotificationsDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostDeployNotificationMail = (
    deployNotificationMailRequestDto: DeployNotificationMailRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1PostDeployNotificationMail200>(
      {url: `/api/v1/deploy/notification/email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deployNotificationMailRequestDto
    },
      options);
    }
  


    export type PostApiV1PostDeployNotificationMailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostDeployNotificationMail>>>
    export type PostApiV1PostDeployNotificationMailMutationBody = DeployNotificationMailRequestDto
    export type PostApiV1PostDeployNotificationMailMutationError = ErrorType<PostApiV1PostDeployNotificationMail400 | void>

    export const usePostApiV1PostDeployNotificationMail = <TError = ErrorType<PostApiV1PostDeployNotificationMail400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostDeployNotificationMail>>, TError,{data: DeployNotificationMailRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostDeployNotificationMail>>, {data: DeployNotificationMailRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostDeployNotificationMail(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostDeployNotificationMail>>, TError, {data: DeployNotificationMailRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1StoreFeedback = (
    feedbackDto: FeedbackDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FeedbackDto>(
      {url: `/api/v1/storeFeedback`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: feedbackDto
    },
      options);
    }
  


    export type PostApiV1StoreFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1StoreFeedback>>>
    export type PostApiV1StoreFeedbackMutationBody = FeedbackDto
    export type PostApiV1StoreFeedbackMutationError = ErrorType<PostApiV1StoreFeedback400 | void>

    export const usePostApiV1StoreFeedback = <TError = ErrorType<PostApiV1StoreFeedback400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1StoreFeedback>>, TError,{data: FeedbackDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1StoreFeedback>>, {data: FeedbackDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1StoreFeedback(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1StoreFeedback>>, TError, {data: FeedbackDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetFeedbackData = (
    extension: '.csv' | '.json',
    params?: GetApiV1GetFeedbackDataParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1GetFeedbackData200One | Blob | void>(
      {url: `/api/v1/feedback${extension}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetFeedbackDataQueryKey = (extension: '.csv' | '.json',
    params?: GetApiV1GetFeedbackDataParams,) => [`/api/v1/feedback${extension}`, ...(params ? [params]: [])];

    
export type GetApiV1GetFeedbackDataQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetFeedbackData>>>
export type GetApiV1GetFeedbackDataQueryError = ErrorType<GetApiV1GetFeedbackData400>

export const useGetApiV1GetFeedbackData = <TData = Awaited<ReturnType<typeof getApiV1GetFeedbackData>>, TError = ErrorType<GetApiV1GetFeedbackData400>>(
 extension: '.csv' | '.json',
    params?: GetApiV1GetFeedbackDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetFeedbackData>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetFeedbackDataQueryKey(extension,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetFeedbackData>>> = ({ signal }) => getApiV1GetFeedbackData(extension,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetFeedbackData>>, TError, TData>(queryKey, queryFn, {enabled: !!(extension),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1GroupChangeNotificationMail = (
    deployNotificationMailRequestDto: DeployNotificationMailRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1GroupChangeNotificationMail200>(
      {url: `/api/v1/notification/groupChange`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deployNotificationMailRequestDto
    },
      options);
    }
  


    export type PostApiV1GroupChangeNotificationMailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1GroupChangeNotificationMail>>>
    export type PostApiV1GroupChangeNotificationMailMutationBody = DeployNotificationMailRequestDto
    export type PostApiV1GroupChangeNotificationMailMutationError = ErrorType<PostApiV1GroupChangeNotificationMail400 | void>

    export const usePostApiV1GroupChangeNotificationMail = <TError = ErrorType<PostApiV1GroupChangeNotificationMail400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1GroupChangeNotificationMail>>, TError,{data: DeployNotificationMailRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1GroupChangeNotificationMail>>, {data: DeployNotificationMailRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1GroupChangeNotificationMail(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1GroupChangeNotificationMail>>, TError, {data: DeployNotificationMailRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GroupsByType = (
    type: string,
    params?: GetApiV1GroupsByTypeParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Group[]>(
      {url: `/api/v1/groups/type/${type}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupsByTypeQueryKey = (type: string,
    params?: GetApiV1GroupsByTypeParams,) => [`/api/v1/groups/type/${type}`, ...(params ? [params]: [])];

    
export type GetApiV1GroupsByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GroupsByType>>>
export type GetApiV1GroupsByTypeQueryError = ErrorType<void>

export const useGetApiV1GroupsByType = <TData = Awaited<ReturnType<typeof getApiV1GroupsByType>>, TError = ErrorType<void>>(
 type: string,
    params?: GetApiV1GroupsByTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GroupsByType>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupsByTypeQueryKey(type,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GroupsByType>>> = ({ signal }) => getApiV1GroupsByType(type,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GroupsByType>>, TError, TData>(queryKey, queryFn, {enabled: !!(type),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GroupsByCategory = (
    category: string,
    params?: GetApiV1GroupsByCategoryParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1GroupsByCategory200>(
      {url: `/api/v1/groups/category/${category}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupsByCategoryQueryKey = (category: string,
    params?: GetApiV1GroupsByCategoryParams,) => [`/api/v1/groups/category/${category}`, ...(params ? [params]: [])];

    
export type GetApiV1GroupsByCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GroupsByCategory>>>
export type GetApiV1GroupsByCategoryQueryError = ErrorType<void>

export const useGetApiV1GroupsByCategory = <TData = Awaited<ReturnType<typeof getApiV1GroupsByCategory>>, TError = ErrorType<void>>(
 category: string,
    params?: GetApiV1GroupsByCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GroupsByCategory>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupsByCategoryQueryKey(category,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GroupsByCategory>>> = ({ signal }) => getApiV1GroupsByCategory(category,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GroupsByCategory>>, TError, TData>(queryKey, queryFn, {enabled: !!(category),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GroupImage = (
    id: string,
    size: 'small' | 'large',
    params?: GetApiV1GroupImageParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Blob>(
      {url: `/api/v1/groups/${id}/image/${size}`, method: 'get',
        params,
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetApiV1GroupImageQueryKey = (id: string,
    size: 'small' | 'large',
    params?: GetApiV1GroupImageParams,) => [`/api/v1/groups/${id}/image/${size}`, ...(params ? [params]: [])];

    
export type GetApiV1GroupImageQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GroupImage>>>
export type GetApiV1GroupImageQueryError = ErrorType<void>

export const useGetApiV1GroupImage = <TData = Awaited<ReturnType<typeof getApiV1GroupImage>>, TError = ErrorType<void>>(
 id: string,
    size: 'small' | 'large',
    params?: GetApiV1GroupImageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GroupImage>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupImageQueryKey(id,size,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GroupImage>>> = ({ signal }) => getApiV1GroupImage(id,size,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GroupImage>>, TError, TData>(queryKey, queryFn, {enabled: !!(id && size),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GroupsBatch = (
    params?: GetApiV1GroupsBatchParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Group[]>(
      {url: `/api/v1/groups/batch`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupsBatchQueryKey = (params?: GetApiV1GroupsBatchParams,) => [`/api/v1/groups/batch`, ...(params ? [params]: [])];

    
export type GetApiV1GroupsBatchQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GroupsBatch>>>
export type GetApiV1GroupsBatchQueryError = ErrorType<void>

export const useGetApiV1GroupsBatch = <TData = Awaited<ReturnType<typeof getApiV1GroupsBatch>>, TError = ErrorType<void>>(
 params?: GetApiV1GroupsBatchParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GroupsBatch>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupsBatchQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GroupsBatch>>> = ({ signal }) => getApiV1GroupsBatch(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GroupsBatch>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1Group = (
    id: string,
    params?: GetApiV1GroupParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Group>(
      {url: `/api/v1/groups/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupQueryKey = (id: string,
    params?: GetApiV1GroupParams,) => [`/api/v1/groups/${id}`, ...(params ? [params]: [])];

    
export type GetApiV1GroupQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Group>>>
export type GetApiV1GroupQueryError = ErrorType<void>

export const useGetApiV1Group = <TData = Awaited<ReturnType<typeof getApiV1Group>>, TError = ErrorType<void>>(
 id: string,
    params?: GetApiV1GroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Group>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Group>>> = ({ signal }) => getApiV1Group(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Group>>, TError, TData>(queryKey, queryFn, {enabled: !!(id),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1Groups = (
    params?: GetApiV1GroupsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1Groups200>(
      {url: `/api/v1/groups`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupsQueryKey = (params?: GetApiV1GroupsParams,) => [`/api/v1/groups`, ...(params ? [params]: [])];

    
export type GetApiV1GroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Groups>>>
export type GetApiV1GroupsQueryError = ErrorType<void>

export const useGetApiV1Groups = <TData = Awaited<ReturnType<typeof getApiV1Groups>>, TError = ErrorType<void>>(
 params?: GetApiV1GroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Groups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Groups>>> = ({ signal }) => getApiV1Groups(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Groups>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GroupsMapping = (
    params?: GetApiV1GroupsMappingParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1GroupsMapping200>(
      {url: `/api/v1/groupsMapping`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GroupsMappingQueryKey = (params?: GetApiV1GroupsMappingParams,) => [`/api/v1/groupsMapping`, ...(params ? [params]: [])];

    
export type GetApiV1GroupsMappingQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GroupsMapping>>>
export type GetApiV1GroupsMappingQueryError = ErrorType<void>

export const useGetApiV1GroupsMapping = <TData = Awaited<ReturnType<typeof getApiV1GroupsMapping>>, TError = ErrorType<void>>(
 params?: GetApiV1GroupsMappingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GroupsMapping>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GroupsMappingQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GroupsMapping>>> = ({ signal }) => getApiV1GroupsMapping(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GroupsMapping>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1LongTermRequest = (
    longTermRequestDto: LongTermRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<LongTermRequest>(
      {url: `/api/v1/longTerm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: longTermRequestDto
    },
      options);
    }
  


    export type PostApiV1LongTermRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1LongTermRequest>>>
    export type PostApiV1LongTermRequestMutationBody = LongTermRequestDto
    export type PostApiV1LongTermRequestMutationError = ErrorType<PostApiV1LongTermRequest400 | void>

    export const usePostApiV1LongTermRequest = <TError = ErrorType<PostApiV1LongTermRequest400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1LongTermRequest>>, TError,{data: LongTermRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1LongTermRequest>>, {data: LongTermRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1LongTermRequest(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1LongTermRequest>>, TError, {data: LongTermRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetLongTermCheck = (
    longTermCheck: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<LongTermCheck>(
      {url: `/api/v1/longTerm/check/${longTermCheck}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetLongTermCheckQueryKey = (longTermCheck: string,) => [`/api/v1/longTerm/check/${longTermCheck}`];

    
export type GetApiV1GetLongTermCheckQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetLongTermCheck>>>
export type GetApiV1GetLongTermCheckQueryError = ErrorType<void>

export const useGetApiV1GetLongTermCheck = <TData = Awaited<ReturnType<typeof getApiV1GetLongTermCheck>>, TError = ErrorType<void>>(
 longTermCheck: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetLongTermCheck>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetLongTermCheckQueryKey(longTermCheck);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetLongTermCheck>>> = ({ signal }) => getApiV1GetLongTermCheck(longTermCheck, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetLongTermCheck>>, TError, TData>(queryKey, queryFn, {enabled: !!(longTermCheck),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1LongTermCheckSubmit = (
    longTermCheck: string,
    longTermCheckDto: LongTermCheckDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<LongTermCheck>(
      {url: `/api/v1/longTerm/check/${longTermCheck}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: longTermCheckDto
    },
      options);
    }
  


    export type PostApiV1LongTermCheckSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1LongTermCheckSubmit>>>
    export type PostApiV1LongTermCheckSubmitMutationBody = LongTermCheckDto
    export type PostApiV1LongTermCheckSubmitMutationError = ErrorType<PostApiV1LongTermCheckSubmit400 | void>

    export const usePostApiV1LongTermCheckSubmit = <TError = ErrorType<PostApiV1LongTermCheckSubmit400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1LongTermCheckSubmit>>, TError,{longTermCheck: string;data: LongTermCheckDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1LongTermCheckSubmit>>, {longTermCheck: string;data: LongTermCheckDto}> = (props) => {
          const {longTermCheck,data} = props ?? {};

          return  postApiV1LongTermCheckSubmit(longTermCheck,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1LongTermCheckSubmit>>, TError, {longTermCheck: string;data: LongTermCheckDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1UploadLongTermCheck = (
    longTermCheck: string,
    longTermCheckUploadDto: LongTermCheckUploadDto,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
formData.append('file', longTermCheckUploadDto.file)

      return customInstance<PostApiV1UploadLongTermCheck200>(
      {url: `/api/v1/longTerm/check/${longTermCheck}/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


    export type PostApiV1UploadLongTermCheckMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1UploadLongTermCheck>>>
    export type PostApiV1UploadLongTermCheckMutationBody = LongTermCheckUploadDto
    export type PostApiV1UploadLongTermCheckMutationError = ErrorType<PostApiV1UploadLongTermCheck400 | void>

    export const usePostApiV1UploadLongTermCheck = <TError = ErrorType<PostApiV1UploadLongTermCheck400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1UploadLongTermCheck>>, TError,{longTermCheck: string;data: LongTermCheckUploadDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1UploadLongTermCheck>>, {longTermCheck: string;data: LongTermCheckUploadDto}> = (props) => {
          const {longTermCheck,data} = props ?? {};

          return  postApiV1UploadLongTermCheck(longTermCheck,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1UploadLongTermCheck>>, TError, {longTermCheck: string;data: LongTermCheckUploadDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetLongTermContract = (
    longTermContract: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<LongTermContract>(
      {url: `/api/v1/longTerm/contract/${longTermContract}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetLongTermContractQueryKey = (longTermContract: string,) => [`/api/v1/longTerm/contract/${longTermContract}`];

    
export type GetApiV1GetLongTermContractQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetLongTermContract>>>
export type GetApiV1GetLongTermContractQueryError = ErrorType<void>

export const useGetApiV1GetLongTermContract = <TData = Awaited<ReturnType<typeof getApiV1GetLongTermContract>>, TError = ErrorType<void>>(
 longTermContract: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetLongTermContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetLongTermContractQueryKey(longTermContract);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetLongTermContract>>> = ({ signal }) => getApiV1GetLongTermContract(longTermContract, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetLongTermContract>>, TError, TData>(queryKey, queryFn, {enabled: !!(longTermContract),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetOneWayConfig = (
    params?: GetApiV1GetOneWayConfigParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<OneWayConfigDto>(
      {url: `/api/v1/config/oneWay`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetOneWayConfigQueryKey = (params?: GetApiV1GetOneWayConfigParams,) => [`/api/v1/config/oneWay`, ...(params ? [params]: [])];

    
export type GetApiV1GetOneWayConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetOneWayConfig>>>
export type GetApiV1GetOneWayConfigQueryError = ErrorType<void>

export const useGetApiV1GetOneWayConfig = <TData = Awaited<ReturnType<typeof getApiV1GetOneWayConfig>>, TError = ErrorType<void>>(
 params?: GetApiV1GetOneWayConfigParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetOneWayConfig>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetOneWayConfigQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetOneWayConfig>>> = ({ signal }) => getApiV1GetOneWayConfig(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetOneWayConfig>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetOneWayReturnStations = (
    params: GetApiV1GetOneWayReturnStationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1GetOneWayReturnStations200>(
      {url: `/api/v1/oneWay/returnStations`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetOneWayReturnStationsQueryKey = (params: GetApiV1GetOneWayReturnStationsParams,) => [`/api/v1/oneWay/returnStations`, ...(params ? [params]: [])];

    
export type GetApiV1GetOneWayReturnStationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetOneWayReturnStations>>>
export type GetApiV1GetOneWayReturnStationsQueryError = ErrorType<GetApiV1GetOneWayReturnStations400 | void>

export const useGetApiV1GetOneWayReturnStations = <TData = Awaited<ReturnType<typeof getApiV1GetOneWayReturnStations>>, TError = ErrorType<GetApiV1GetOneWayReturnStations400 | void>>(
 params: GetApiV1GetOneWayReturnStationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetOneWayReturnStations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetOneWayReturnStationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetOneWayReturnStations>>> = ({ signal }) => getApiV1GetOneWayReturnStations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetOneWayReturnStations>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1PostEstimateOneWay = (
    oneWayRequestDto: OneWayRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OneWayEstimationDto>(
      {url: `/api/v1/oneWay/estimate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oneWayRequestDto
    },
      options);
    }
  


    export type PostApiV1PostEstimateOneWayMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostEstimateOneWay>>>
    export type PostApiV1PostEstimateOneWayMutationBody = OneWayRequestDto
    export type PostApiV1PostEstimateOneWayMutationError = ErrorType<PostApiV1PostEstimateOneWay400 | void>

    export const usePostApiV1PostEstimateOneWay = <TError = ErrorType<PostApiV1PostEstimateOneWay400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostEstimateOneWay>>, TError,{data: OneWayRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostEstimateOneWay>>, {data: OneWayRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1PostEstimateOneWay(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostEstimateOneWay>>, TError, {data: OneWayRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1CreateOneWayReservation = (
    oneWayRequestDto: OneWayRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/oneWay`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oneWayRequestDto
    },
      options);
    }
  


    export type PostApiV1CreateOneWayReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CreateOneWayReservation>>>
    export type PostApiV1CreateOneWayReservationMutationBody = OneWayRequestDto
    export type PostApiV1CreateOneWayReservationMutationError = ErrorType<PostApiV1CreateOneWayReservation400 | void>

    export const usePostApiV1CreateOneWayReservation = <TError = ErrorType<PostApiV1CreateOneWayReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CreateOneWayReservation>>, TError,{data: OneWayRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CreateOneWayReservation>>, {data: OneWayRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1CreateOneWayReservation(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CreateOneWayReservation>>, TError, {data: OneWayRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetOneWayDistance = (
    params: GetApiV1GetOneWayDistanceParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<OneWayDistanceDto>(
      {url: `/api/v1/oneWay/distance`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetOneWayDistanceQueryKey = (params: GetApiV1GetOneWayDistanceParams,) => [`/api/v1/oneWay/distance`, ...(params ? [params]: [])];

    
export type GetApiV1GetOneWayDistanceQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetOneWayDistance>>>
export type GetApiV1GetOneWayDistanceQueryError = ErrorType<GetApiV1GetOneWayDistance400 | void>

export const useGetApiV1GetOneWayDistance = <TData = Awaited<ReturnType<typeof getApiV1GetOneWayDistance>>, TError = ErrorType<GetApiV1GetOneWayDistance400 | void>>(
 params: GetApiV1GetOneWayDistanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetOneWayDistance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetOneWayDistanceQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetOneWayDistance>>> = ({ signal }) => getApiV1GetOneWayDistance(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetOneWayDistance>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetRentForFreeOffers = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<RentForFreeOffersResponseDto>(
      {url: `/api/v1/rentForFreeOffers`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetRentForFreeOffersQueryKey = () => [`/api/v1/rentForFreeOffers`];

    
export type GetApiV1GetRentForFreeOffersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetRentForFreeOffers>>>
export type GetApiV1GetRentForFreeOffersQueryError = ErrorType<void>

export const useGetApiV1GetRentForFreeOffers = <TData = Awaited<ReturnType<typeof getApiV1GetRentForFreeOffers>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetRentForFreeOffers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetRentForFreeOffersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetRentForFreeOffers>>> = ({ signal }) => getApiV1GetRentForFreeOffers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetRentForFreeOffers>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1CreateRentForFreeReservation = (
    rentForFreeOffer: number,
    rentForFreeRequestDto: RentForFreeRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/rentForFree/${rentForFreeOffer}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rentForFreeRequestDto
    },
      options);
    }
  


    export type PostApiV1CreateRentForFreeReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CreateRentForFreeReservation>>>
    export type PostApiV1CreateRentForFreeReservationMutationBody = RentForFreeRequestDto
    export type PostApiV1CreateRentForFreeReservationMutationError = ErrorType<PostApiV1CreateRentForFreeReservation400 | void>

    export const usePostApiV1CreateRentForFreeReservation = <TError = ErrorType<PostApiV1CreateRentForFreeReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CreateRentForFreeReservation>>, TError,{rentForFreeOffer: number;data: RentForFreeRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CreateRentForFreeReservation>>, {rentForFreeOffer: number;data: RentForFreeRequestDto}> = (props) => {
          const {rentForFreeOffer,data} = props ?? {};

          return  postApiV1CreateRentForFreeReservation(rentForFreeOffer,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CreateRentForFreeReservation>>, TError, {rentForFreeOffer: number;data: RentForFreeRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const putApiV1UpdateReservationGroup = (
    reservation: string,
    reservationGroupDto: ReservationGroupDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/reservation/${reservation}/group`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: reservationGroupDto
    },
      options);
    }
  


    export type PutApiV1UpdateReservationGroupMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1UpdateReservationGroup>>>
    export type PutApiV1UpdateReservationGroupMutationBody = ReservationGroupDto
    export type PutApiV1UpdateReservationGroupMutationError = ErrorType<PutApiV1UpdateReservationGroup400 | void>

    export const usePutApiV1UpdateReservationGroup = <TError = ErrorType<PutApiV1UpdateReservationGroup400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1UpdateReservationGroup>>, TError,{reservation: string;data: ReservationGroupDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1UpdateReservationGroup>>, {reservation: string;data: ReservationGroupDto}> = (props) => {
          const {reservation,data} = props ?? {};

          return  putApiV1UpdateReservationGroup(reservation,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1UpdateReservationGroup>>, TError, {reservation: string;data: ReservationGroupDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetReservationGroups = (
    reservation: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ReservationGroupsDto>(
      {url: `/api/v1/reservation/${reservation}/groups`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetReservationGroupsQueryKey = (reservation: string,) => [`/api/v1/reservation/${reservation}/groups`];

    
export type GetApiV1GetReservationGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetReservationGroups>>>
export type GetApiV1GetReservationGroupsQueryError = ErrorType<void>

export const useGetApiV1GetReservationGroups = <TData = Awaited<ReturnType<typeof getApiV1GetReservationGroups>>, TError = ErrorType<void>>(
 reservation: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetReservationGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetReservationGroupsQueryKey(reservation);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetReservationGroups>>> = ({ signal }) => getApiV1GetReservationGroups(reservation, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetReservationGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(reservation),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetReservation = (
    reservation: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Reservation>(
      {url: `/api/v1/reservation/${reservation}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetReservationQueryKey = (reservation: string,) => [`/api/v1/reservation/${reservation}`];

    
export type GetApiV1GetReservationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetReservation>>>
export type GetApiV1GetReservationQueryError = ErrorType<void>

export const useGetApiV1GetReservation = <TData = Awaited<ReturnType<typeof getApiV1GetReservation>>, TError = ErrorType<void>>(
 reservation: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetReservation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetReservationQueryKey(reservation);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetReservation>>> = ({ signal }) => getApiV1GetReservation(reservation, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetReservation>>, TError, TData>(queryKey, queryFn, {enabled: !!(reservation),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const putApiV1UpdateReservation = (
    reservation: string,
    reservationDtoUpdate: ReservationDtoUpdate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/reservation/${reservation}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: reservationDtoUpdate
    },
      options);
    }
  


    export type PutApiV1UpdateReservationMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1UpdateReservation>>>
    export type PutApiV1UpdateReservationMutationBody = ReservationDtoUpdate
    export type PutApiV1UpdateReservationMutationError = ErrorType<PutApiV1UpdateReservation400 | void>

    export const usePutApiV1UpdateReservation = <TError = ErrorType<PutApiV1UpdateReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1UpdateReservation>>, TError,{reservation: string;data: ReservationDtoUpdate}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1UpdateReservation>>, {reservation: string;data: ReservationDtoUpdate}> = (props) => {
          const {reservation,data} = props ?? {};

          return  putApiV1UpdateReservation(reservation,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1UpdateReservation>>, TError, {reservation: string;data: ReservationDtoUpdate}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1CreateReservation = (
    reservationDtoCreate: ReservationDtoCreate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/reservation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reservationDtoCreate
    },
      options);
    }
  


    export type PostApiV1CreateReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CreateReservation>>>
    export type PostApiV1CreateReservationMutationBody = ReservationDtoCreate
    export type PostApiV1CreateReservationMutationError = ErrorType<PostApiV1CreateReservation400 | void>

    export const usePostApiV1CreateReservation = <TError = ErrorType<PostApiV1CreateReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CreateReservation>>, TError,{data: ReservationDtoCreate}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CreateReservation>>, {data: ReservationDtoCreate}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1CreateReservation(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CreateReservation>>, TError, {data: ReservationDtoCreate}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetReservationPrices = (
    reservation: string,
    category: string,
    sequenceNumber: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/api/v1/reservation/${reservation}/prices/${category}/${sequenceNumber}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetReservationPricesQueryKey = (reservation: string,
    category: string,
    sequenceNumber: string,) => [`/api/v1/reservation/${reservation}/prices/${category}/${sequenceNumber}`];

    
export type GetApiV1GetReservationPricesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetReservationPrices>>>
export type GetApiV1GetReservationPricesQueryError = ErrorType<GetApiV1GetReservationPrices400>

export const useGetApiV1GetReservationPrices = <TData = Awaited<ReturnType<typeof getApiV1GetReservationPrices>>, TError = ErrorType<GetApiV1GetReservationPrices400>>(
 reservation: string,
    category: string,
    sequenceNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetReservationPrices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetReservationPricesQueryKey(reservation,category,sequenceNumber);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetReservationPrices>>> = ({ signal }) => getApiV1GetReservationPrices(reservation,category,sequenceNumber, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetReservationPrices>>, TError, TData>(queryKey, queryFn, {enabled: !!(reservation && category && sequenceNumber),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1PostEstimateReservation = (
    reservation: string,
    reservationEstimationDto: ReservationEstimationDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Estimation>(
      {url: `/api/v1/reservation/${reservation}/estimate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reservationEstimationDto
    },
      options);
    }
  


    export type PostApiV1PostEstimateReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostEstimateReservation>>>
    export type PostApiV1PostEstimateReservationMutationBody = ReservationEstimationDto
    export type PostApiV1PostEstimateReservationMutationError = ErrorType<PostApiV1PostEstimateReservation400 | void>

    export const usePostApiV1PostEstimateReservation = <TError = ErrorType<PostApiV1PostEstimateReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostEstimateReservation>>, TError,{reservation: string;data: ReservationEstimationDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostEstimateReservation>>, {reservation: string;data: ReservationEstimationDto}> = (props) => {
          const {reservation,data} = props ?? {};

          return  postApiV1PostEstimateReservation(reservation,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostEstimateReservation>>, TError, {reservation: string;data: ReservationEstimationDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostSubmitReservation = (
    reservation: string,
    reservationSubmitDto: ReservationSubmitDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/reservation/${reservation}/submit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reservationSubmitDto
    },
      options);
    }
  


    export type PostApiV1PostSubmitReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostSubmitReservation>>>
    export type PostApiV1PostSubmitReservationMutationBody = ReservationSubmitDto
    export type PostApiV1PostSubmitReservationMutationError = ErrorType<PostApiV1PostSubmitReservation400 | void>

    export const usePostApiV1PostSubmitReservation = <TError = ErrorType<PostApiV1PostSubmitReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostSubmitReservation>>, TError,{reservation: string;data: ReservationSubmitDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostSubmitReservation>>, {reservation: string;data: ReservationSubmitDto}> = (props) => {
          const {reservation,data} = props ?? {};

          return  postApiV1PostSubmitReservation(reservation,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostSubmitReservation>>, TError, {reservation: string;data: ReservationSubmitDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetBooking = (
    uuid: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Reservation>(
      {url: `/api/v1/booking/${uuid}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetBookingQueryKey = (uuid: string,) => [`/api/v1/booking/${uuid}`];

    
export type GetApiV1GetBookingQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetBooking>>>
export type GetApiV1GetBookingQueryError = ErrorType<void>

export const useGetApiV1GetBooking = <TData = Awaited<ReturnType<typeof getApiV1GetBooking>>, TError = ErrorType<void>>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetBooking>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetBookingQueryKey(uuid);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetBooking>>> = ({ signal }) => getApiV1GetBooking(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetBooking>>, TError, TData>(queryKey, queryFn, {enabled: !!(uuid),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deleteApiV1CancelBooking = (
    uuid: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/booking/${uuid}`, method: 'delete'
    },
      options);
    }
  


    export type DeleteApiV1CancelBookingMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1CancelBooking>>>
    
    export type DeleteApiV1CancelBookingMutationError = ErrorType<DeleteApiV1CancelBooking400 | void>

    export const useDeleteApiV1CancelBooking = <TError = ErrorType<DeleteApiV1CancelBooking400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1CancelBooking>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1CancelBooking>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  deleteApiV1CancelBooking(uuid,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteApiV1CancelBooking>>, TError, {uuid: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV2GetReservationGroups = (
    reservation: string,
    params?: GetApiV2GetReservationGroupsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ReservationGroupsDto>(
      {url: `/api/v2/reservation/${reservation}/groups`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV2GetReservationGroupsQueryKey = (reservation: string,
    params?: GetApiV2GetReservationGroupsParams,) => [`/api/v2/reservation/${reservation}/groups`, ...(params ? [params]: [])];

    
export type GetApiV2GetReservationGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV2GetReservationGroups>>>
export type GetApiV2GetReservationGroupsQueryError = ErrorType<void>

export const useGetApiV2GetReservationGroups = <TData = Awaited<ReturnType<typeof getApiV2GetReservationGroups>>, TError = ErrorType<void>>(
 reservation: string,
    params?: GetApiV2GetReservationGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV2GetReservationGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2GetReservationGroupsQueryKey(reservation,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV2GetReservationGroups>>> = ({ signal }) => getApiV2GetReservationGroups(reservation,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV2GetReservationGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(reservation),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV2GetReservationPrices = (
    reservation: string,
    category: string,
    sequenceNumber: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/api/v2/reservation/${reservation}/prices/${category}/${sequenceNumber}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV2GetReservationPricesQueryKey = (reservation: string,
    category: string,
    sequenceNumber: string,) => [`/api/v2/reservation/${reservation}/prices/${category}/${sequenceNumber}`];

    
export type GetApiV2GetReservationPricesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV2GetReservationPrices>>>
export type GetApiV2GetReservationPricesQueryError = ErrorType<GetApiV2GetReservationPrices400>

export const useGetApiV2GetReservationPrices = <TData = Awaited<ReturnType<typeof getApiV2GetReservationPrices>>, TError = ErrorType<GetApiV2GetReservationPrices400>>(
 reservation: string,
    category: string,
    sequenceNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV2GetReservationPrices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV2GetReservationPricesQueryKey(reservation,category,sequenceNumber);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV2GetReservationPrices>>> = ({ signal }) => getApiV2GetReservationPrices(reservation,category,sequenceNumber, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV2GetReservationPrices>>, TError, TData>(queryKey, queryFn, {enabled: !!(reservation && category && sequenceNumber),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetSales = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1GetSales200>(
      {url: `/api/v1/sales`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetSalesQueryKey = () => [`/api/v1/sales`];

    
export type GetApiV1GetSalesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetSales>>>
export type GetApiV1GetSalesQueryError = ErrorType<void>

export const useGetApiV1GetSales = <TData = Awaited<ReturnType<typeof getApiV1GetSales>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetSales>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetSalesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetSales>>> = ({ signal }) => getApiV1GetSales(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetSales>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1CreateSaleReservation = (
    saleOffer: number,
    saleRequestDto: SaleRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Reservation>(
      {url: `/api/v1/sales/${saleOffer}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saleRequestDto
    },
      options);
    }
  


    export type PostApiV1CreateSaleReservationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1CreateSaleReservation>>>
    export type PostApiV1CreateSaleReservationMutationBody = SaleRequestDto
    export type PostApiV1CreateSaleReservationMutationError = ErrorType<PostApiV1CreateSaleReservation400 | void>

    export const usePostApiV1CreateSaleReservation = <TError = ErrorType<PostApiV1CreateSaleReservation400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1CreateSaleReservation>>, TError,{saleOffer: number;data: SaleRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1CreateSaleReservation>>, {saleOffer: number;data: SaleRequestDto}> = (props) => {
          const {saleOffer,data} = props ?? {};

          return  postApiV1CreateSaleReservation(saleOffer,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1CreateSaleReservation>>, TError, {saleOffer: number;data: SaleRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const postApiV1PostEstimateSale = (
    saleOffer: number,
    saleRequestDto: SaleRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Estimation>(
      {url: `/api/v1/sales/${saleOffer}/estimate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saleRequestDto
    },
      options);
    }
  


    export type PostApiV1PostEstimateSaleMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1PostEstimateSale>>>
    export type PostApiV1PostEstimateSaleMutationBody = SaleRequestDto
    export type PostApiV1PostEstimateSaleMutationError = ErrorType<PostApiV1PostEstimateSale400 | void>

    export const usePostApiV1PostEstimateSale = <TError = ErrorType<PostApiV1PostEstimateSale400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1PostEstimateSale>>, TError,{saleOffer: number;data: SaleRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1PostEstimateSale>>, {saleOffer: number;data: SaleRequestDto}> = (props) => {
          const {saleOffer,data} = props ?? {};

          return  postApiV1PostEstimateSale(saleOffer,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1PostEstimateSale>>, TError, {saleOffer: number;data: SaleRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1Stations = (
    params?: GetApiV1StationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<GetApiV1Stations200>(
      {url: `/api/v1/stations`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1StationsQueryKey = (params?: GetApiV1StationsParams,) => [`/api/v1/stations`, ...(params ? [params]: [])];

    
export type GetApiV1StationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Stations>>>
export type GetApiV1StationsQueryError = ErrorType<void>

export const useGetApiV1Stations = <TData = Awaited<ReturnType<typeof getApiV1Stations>>, TError = ErrorType<void>>(
 params?: GetApiV1StationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Stations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1StationsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Stations>>> = ({ signal }) => getApiV1Stations(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Stations>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1StationsByCity = (
    filterCity: string,
    params?: GetApiV1StationsByCityParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Station[]>(
      {url: `/api/v1/stations/city/${filterCity}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1StationsByCityQueryKey = (filterCity: string,
    params?: GetApiV1StationsByCityParams,) => [`/api/v1/stations/city/${filterCity}`, ...(params ? [params]: [])];

    
export type GetApiV1StationsByCityQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1StationsByCity>>>
export type GetApiV1StationsByCityQueryError = ErrorType<void>

export const useGetApiV1StationsByCity = <TData = Awaited<ReturnType<typeof getApiV1StationsByCity>>, TError = ErrorType<void>>(
 filterCity: string,
    params?: GetApiV1StationsByCityParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1StationsByCity>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1StationsByCityQueryKey(filterCity,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1StationsByCity>>> = ({ signal }) => getApiV1StationsByCity(filterCity,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1StationsByCity>>, TError, TData>(queryKey, queryFn, {enabled: !!(filterCity),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1Station = (
    id: string,
    params?: GetApiV1StationParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Station>(
      {url: `/api/v1/stations/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1StationQueryKey = (id: string,
    params?: GetApiV1StationParams,) => [`/api/v1/stations/${id}`, ...(params ? [params]: [])];

    
export type GetApiV1StationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Station>>>
export type GetApiV1StationQueryError = ErrorType<void>

export const useGetApiV1Station = <TData = Awaited<ReturnType<typeof getApiV1Station>>, TError = ErrorType<void>>(
 id: string,
    params?: GetApiV1StationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Station>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1StationQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Station>>> = ({ signal }) => getApiV1Station(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Station>>, TError, TData>(queryKey, queryFn, {enabled: !!(id),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1Holidays = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Holiday[]>(
      {url: `/api/v1/holidays`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1HolidaysQueryKey = () => [`/api/v1/holidays`];

    
export type GetApiV1HolidaysQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1Holidays>>>
export type GetApiV1HolidaysQueryError = ErrorType<void>

export const useGetApiV1Holidays = <TData = Awaited<ReturnType<typeof getApiV1Holidays>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1Holidays>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1HolidaysQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1Holidays>>> = ({ signal }) => getApiV1Holidays(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1Holidays>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiV1GetQuestionnaire = (
    questionnaireId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<Questionnaire>(
      {url: `/api/v1/questionnaires/${questionnaireId}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiV1GetQuestionnaireQueryKey = (questionnaireId: string,) => [`/api/v1/questionnaires/${questionnaireId}`];

    
export type GetApiV1GetQuestionnaireQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetQuestionnaire>>>
export type GetApiV1GetQuestionnaireQueryError = ErrorType<void>

export const useGetApiV1GetQuestionnaire = <TData = Awaited<ReturnType<typeof getApiV1GetQuestionnaire>>, TError = ErrorType<void>>(
 questionnaireId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetQuestionnaire>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetQuestionnaireQueryKey(questionnaireId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetQuestionnaire>>> = ({ signal }) => getApiV1GetQuestionnaire(questionnaireId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetQuestionnaire>>, TError, TData>(queryKey, queryFn, {enabled: !!(questionnaireId),  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const putApiV1UpdateQuestionnaire = (
    questionnaireId: string,
    questionnaireUpdateRequestDto: QuestionnaireUpdateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Questionnaire>(
      {url: `/api/v1/questionnaires/${questionnaireId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: questionnaireUpdateRequestDto
    },
      options);
    }
  


    export type PutApiV1UpdateQuestionnaireMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1UpdateQuestionnaire>>>
    export type PutApiV1UpdateQuestionnaireMutationBody = QuestionnaireUpdateRequestDto
    export type PutApiV1UpdateQuestionnaireMutationError = ErrorType<PutApiV1UpdateQuestionnaire400 | void>

    export const usePutApiV1UpdateQuestionnaire = <TError = ErrorType<PutApiV1UpdateQuestionnaire400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1UpdateQuestionnaire>>, TError,{questionnaireId: string;data: QuestionnaireUpdateRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1UpdateQuestionnaire>>, {questionnaireId: string;data: QuestionnaireUpdateRequestDto}> = (props) => {
          const {questionnaireId,data} = props ?? {};

          return  putApiV1UpdateQuestionnaire(questionnaireId,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof putApiV1UpdateQuestionnaire>>, TError, {questionnaireId: string;data: QuestionnaireUpdateRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const getApiV1GetFeedback = (
    params?: GetApiV1GetFeedbackParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FeedbackResponseDto>(
      {url: `/api/v1/questionnaires/feedback`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetApiV1GetFeedbackQueryKey = (params?: GetApiV1GetFeedbackParams,) => [`/api/v1/questionnaires/feedback`, ...(params ? [params]: [])];

    
export type GetApiV1GetFeedbackQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1GetFeedback>>>
export type GetApiV1GetFeedbackQueryError = ErrorType<GetApiV1GetFeedback400 | void>

export const useGetApiV1GetFeedback = <TData = Awaited<ReturnType<typeof getApiV1GetFeedback>>, TError = ErrorType<GetApiV1GetFeedback400 | void>>(
 params?: GetApiV1GetFeedbackParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1GetFeedback>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiV1GetFeedbackQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1GetFeedback>>> = ({ signal }) => getApiV1GetFeedback(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiV1GetFeedback>>, TError, TData>(queryKey, queryFn, {  staleTime: 300000,  ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const postApiV1UnsubscribeSurvey = (
    surveyUnsubscribeRequestDto: SurveyUnsubscribeRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostApiV1UnsubscribeSurvey200>(
      {url: `/api/v1/survey/unsubscribe`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: surveyUnsubscribeRequestDto
    },
      options);
    }
  


    export type PostApiV1UnsubscribeSurveyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1UnsubscribeSurvey>>>
    export type PostApiV1UnsubscribeSurveyMutationBody = SurveyUnsubscribeRequestDto
    export type PostApiV1UnsubscribeSurveyMutationError = ErrorType<PostApiV1UnsubscribeSurvey400 | void>

    export const usePostApiV1UnsubscribeSurvey = <TError = ErrorType<PostApiV1UnsubscribeSurvey400 | void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1UnsubscribeSurvey>>, TError,{data: SurveyUnsubscribeRequestDto}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1UnsubscribeSurvey>>, {data: SurveyUnsubscribeRequestDto}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1UnsubscribeSurvey(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postApiV1UnsubscribeSurvey>>, TError, {data: SurveyUnsubscribeRequestDto}, TContext>(mutationFn, mutationOptions)
    }
    
